.custom-remove-filter {
  width: fit-content;
  color: white;
  background-color: #1156a4;
  border-radius: 8px;
  padding: 10px;
  text-decoration: none;
  margin-top: 10px;
  margin-right: 10px;
}

.custom-remove-filter:hover {
  color: white;
  text-decoration: none;
}
