/******************************************
	Rating/Comment system
******************************************/
.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  content: "\f005";
  cursor: pointer;
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}
.rating-big .fa,
.average-rating .fas.fa-star,
.comment .fas.fa-star {
  color: #ffd700;
  padding: 2px;
  text-shadow: 1px 1px 1px #000;
}
.rating-big .average {
  font-size: 40px;
  font-weight: bold;
  color: #ffd700;
  text-shadow: 1px 1px 1px #000;
}
.comment-paging a {
  cursor: pointer;
}
.review-summary .progress-bar {
  background: #ffd700;
}
/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
} /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}
