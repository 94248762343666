.left-container {
  background-color: #f6f8fb;
  border-radius: 8px;
  padding: 40px 32px;
}

.option-2 {
  margin-top: 70px;
}

@media (max-width: 991px) {
  .left-container {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .left-container {
    margin-top: 20px;
    padding: 20px 10px;
  }

  .option-2 {
    margin-top: 36px;
  }
}
