.footer-info-links-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.custom-footer-nav-link, custom-footer-nav-link{
  &:hover {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
}

.dropdown-toggle {
  cursor: pointer;
}

.custom-collapse {
  width: 215px;
  display: flex;
  justify-content: space-between;
}

.custom-collapse-row {
  width: 215px;
  margin-left: 1rem !important;
  margin-right: 0 !important;
  .custom-collapse-col {
    padding: 0;
    .multi-collapse {
      .card-body {
        padding: 4px 0;
        background-color: transparent;
        border: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .footer-info-links-container {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .fix-height-md-100 {
    height: 165px;
  }
}
