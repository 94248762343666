/******************************************************************************
 * Quickbuy modal
 ******************************************************************************/
.product-details {
  header {
    border-bottom: 1px solid $gray-400;

    .name {
      color: #000;
    }
  }
  a.thumbnail {
    display: block;

    &.active {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #1156a4;
        border-radius: 8px;

        @include mobile() {
          display: none
        }
      }
    }

    @include mobile () {
      width: 100%;
    }
  }

  .product-filters {
    .btn {
      &.image-option {
        padding: 0;
      }
    }
  }

  #zoomMain {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }
}

.sticker {
  left: 10px;
  position: absolute;
  top: 0;
  z-index: $zindex-dropdown;
}

/******************************************************************************
 * Category page and related products
 ******************************************************************************/
.product-preview {
  article {
    height: calc(100% - 2em);
    @extend .pt-3;

    &:hover {
      box-shadow: 0px 0px 5px $gray-400;
    }
  }

  .card-header {
    display: flex;
    justify-content: space-between;
  }
  .product-content {
    position: relative;
    .sticker {
      position: absolute;
      top: 0;
      z-index: $z-index-100;
    }

    .btn-compare-quickbuy {
      display: none;
      padding: 0.1em 0.4em;
      position: absolute;
      right: 1em;
      top: 0;
      z-index: 1;
    }

    .images-container {
      .container-btn-quickbuy {
        display: none !important;
        left: 10%;
        position: absolute;
        top: 50%;
        width: 80%;

        @include media-breakpoint-up(sm) {
          top: 80%;
        }
      }

      img {
        position: relative;
        top: auto;
        left: auto;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;

        @include transition(opacity 0.4s ease-out);
      }

      img + img {
        position: absolute;
        top: 50%;
        opacity: 0;
        right: 50%;
        transform: translate(50%, -50%);
      }

      &:hover {
        img {
          opacity: 1;
        }
        .container-btn-quickbuy {
          display: flex !important;
        }
      }
    }

    &:hover {
      .btn-compare-quickbuy {
        display: block;
      }
    }
  }

  .product-options {
    ul {
      li {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .btn {
      padding: ($line-height-base / 4);
      line-height: $line-height-base;
    }

    img {
      border: 1px solid $gray-600;
      display: block;
      height: 1.5rem;
    }
  }
  .btn-group-justified {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .btn-group {
      flex-grow: 1;
    }
  }
}

.featured-products {
  @extend .align-items-center;
  @extend .d-flex;
  @extend .h3;
  @extend .text-center;
  @extend .text-primary;
}
