.block-registration {
    h2 {
        @include mobile() {
            font-size: 20px;
            font-weight: bold;
        }

        a {
            span {
                @include mobile() {
                    font-size: 20px !important;
                    font-weight: bold;
                }
            }
        }
    }
    .register-checks {
        p {
            font-size: 16px;
        }
        ul {
            @include mobile() {
                padding-left: 24px;
            }
            li {
                position: relative;

                h5 {
                    font-weight: 500;
                }
                &::before {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: 400;
                    line-height: 1;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    line-height: 1.5rem;
                    content: "\f00c";
                    color: #11a44d;
                    position: absolute;
                    top: 0;
                    left: -10%;
                    text-decoration: none !important;
                    text-align: center;
                }

                &::marker {
                    content: "";
                    display: none !important;
                }
            }
        }
    }
}
