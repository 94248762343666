.orderMgr_rbsBT-container {
  background-color: #e6effb;
  padding: 20px;
  border-radius: 8px;
}

.orderMgr_rbsBT-thank-you-container {
  text-align: center;
}

.orderMgr_rbsBt-row2 {
  background-color: #e6effb;
  padding: 79px 100px;
  margin-top: 57px;
  border-radius: 8px;

  dt {
    font-weight: normal !important;
  }
}

.orderMgr_rbsBt-row3 {
  background-color: #e6effb;
  padding: 56px 100px;
  margin-top: 38px;
  border-radius: 8px;

  p {
    width: 499px;
  }
}

.email-and-print-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orderMgr_rbsBT-text-alignments {
  text-align: center;
}

@media (max-width: 991px) {
  .orderMgr_rbsBt-row2 {
    padding: 24px 16px;
    margin-top: 55px;
  }

  .orderMgr_rbsBt-row3 {
    padding: 24px 16px;
    margin-top: 26px;

    p {
      width: fit-content;
    }
  }

  .orderMgr_rbsBt-row2-column2 {
    p {
      font-size: 15px !important;
    }
  }
}

@media (max-width: 767px) {
  .orderMgr_rbsBT-text-alignments {
    text-align: left;
  }
}

@media (max-width: 510px) {
  .orderMgr_rbsBT-custom-font {
    font-size: 9px !important;
    padding: 10px 18px !important;
  }

  .orderMgr_rbsBt-row2-column2 {
    p {
      font-size: 12px !important;
    }
  }
}
