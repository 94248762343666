// Left Links
.accountSummary-nav-tabs {
  position: relative;
  flex-direction: column;
  background: #f6f8fb;
  border-radius: 8px;
  padding: 40px 20px !important;
  border: none !important;
}

.accountSummary-nav-link {
  position: relative;
  color: black !important;
  padding: 0 !important;
  margin-bottom: 10px !important;
}

.accountSummary-nav-link.active {
  background-color: transparent !important;
  border: none !important;
  font-weight: bold;
}

.accountSummary-nav-link.active::after {
  position: absolute;
  right: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
}

.left-link-icons {
  width: 17px;
}

// Right Contents
.accountSummary-tab-pan {
  background: #e6effb;
  border-radius: 8px;
  //   padding: 30px 30px 0;
  padding: 30px;
  margin-top: 36px !important;
}

.accountSummary-btn-oldpassword-eye {
  position: absolute !important;
  right: 10px;
  top: 7px;
}

.accountSummary-btn-newpassword-eye {
  position: absolute !important;
  right: 10px;
  top: 7px;
}

.accountSummary-adderss-container {
  display: flex;
  flex-direction: column;
}

.accountSummary-news-letter-label {
  width: 390px;
}

.accountSummary-news-letter-link {
  font-size: 18px !important;
  color: black;
  text-decoration: underline;
}

@media (max-width: 991px) {
  .accountSummary-news-letter-label {
    width: fit-content;
  }
}
