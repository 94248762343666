.wishlistMgr-outer-container {
  padding: 50px 105px;
  border-radius: 8px;
  background-color: #e6effb;
}

@media (max-width: 1200px) {
  .wishlistMgr-outer-container {
    padding: 50px 0px;
  }
}

@media (max-width: 768px) {
  .wishlistMgr-outer-container {
    padding: 0px;
    background-color: unset;
  }
}
