.orderMgr_address {
  max-width: 448px;
}

.cart-last-step {
  .orders-list {
    display: block !important;
  }

  .text-all-step {
    display: none;
  }

  .text-last-step {
    display: block;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .orderMgr_address {
    max-width: 388px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .orderMgr_address {
    max-width: 238px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .orderMgr_address {
    max-width: 320px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .orderMgr_address {
    max-width: 268px;
  }
}

@media (max-width: 576px) {
  .secureStripe2Form-proceed-to-pay {
    font-size: 12px !important;
  }
}
