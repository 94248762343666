body {
  padding-top: $height-navbar-brand * 1px;

  @include media-breakpoint-up(lg) {
    padding-top: $height-navbar-brand-lg * 1px;
  }
}

.body-extra-margin {
  padding-top: $height-navbar-brand + 67px;

  @include media-breakpoint-up(lg) {
    padding-top: $height-navbar-brand-lg + 51px;
  }
}

#content_cookie_policy_gdpr {
  background-color: $white;
  box-shadow: 0px -3px 5px $gray-300;
  bottom: 0;
  margin-bottom: 0;
  padding: 1rem;
  position: fixed;
  z-index: $zindex-sticky;
  width: 100%;
}
#cookies_policy {
  max-height: 400px;
  overflow-y: scroll;
}

#navbar-brand {
  z-index: 1000;
}


#searchContainer{
  background-color: $gray-300;

  .typeahead{
    //top: 35px;
    width: 100%;
  }
}

// START: Return to top button
#return-to-top {
  background: $black;
  border: none;
  bottom: 10px;
  cursor: pointer;
  display: none;
  height: 35px;
  opacity: 0.8;
  position: fixed;
  right: 10px;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 35px;

  i {
    color: $white;
    margin: 0;
    position: relative;
    transition: all 0.3s ease;
  }

  &:hover {
    opacity: 1;
    i {
      top: -3px;
    }
  }

  @include btn-a-style;
}
// END: Return to top button


.site_footer {
  margin-top: 60px;
  padding-bottom: 20px;
  background-color: #F6F8FB;
  color: #0C0C0C;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a {
    color: #0C0C0C;
    padding: 0px;
    &:hover, &:visited, &:active {
      text-decoration: none;
    }
  }
}

.nav-item{
  padding: 0 0 8px;
}

.navbar-brand {
  left: 50%;
  margin-left: -100px;
  position: absolute;
  width: 200px;

  @include media-breakpoint-down(xs) {
    max-width: 150px;
  }
}