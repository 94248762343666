//
// MIXINS !!!
// ======================================================

// LOADING
// idea from http://codepen.io/Metty/details/lgJck
// ======================================================

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin loading($color1, $color2, $size, $border, $height-mult) {
  position: relative;
  min-height: ($size * $height-mult);
  min-width: ($size + 10);
  overflow: hidden;
  pointer-events: none;

  > * {
    display: none !important;
    visibility: hidden !important;
  }

  &:before,
  &:after {
    content: "" !important;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border: $border solid $color1;
    border-radius: 50%;
  }

  &:before {
    width: $size;
    height: $size;
    margin-top: -($size / 2);
    margin-left: -($size / 2);
    border-right-color: $color2;
    border-left-color: $color2;
    animation: rotation 3s linear infinite;
  }

  &:after {
    width: $size * (3/4);
    height: $size * (3/4);
    margin-top: -(($size / 4) + 10);
    margin-left: -(($size / 4) + 10);
    border-top-color: $color2;
    border-bottom-color: $color2;
    border-left-color: lighten($color1, 7%);
    border-right-color: lighten($color1, 7%);
    animation: rotation 1s linear infinite;
  }
}

@mixin btn-a-style {
  border: none;

  &:active,
  &:focus {
    border: none;
    outline: 0;
  }
}

@mixin btn-social($color, $background-color) {
  background-color: $background-color;
  color: $color;

  &:hover,
  &:focus,
  &:active {
    background-color: darken($background-color, 5%);
  }
}

@mixin mobile() {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet() {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin tabletAir() {
  @media (max-width: $breakpoint-tablet-air) {
    @content;
  }
}
@mixin tabletMini() {
  @media (max-width: $breakpoint-tablet-mini) {
    @content;
  }
}
