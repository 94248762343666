.cart-outer-container {
  margin-bottom: 86px;
  border-radius: 8px;
}

.product-outer-container {
  background-color: #e6effb;
  padding: 8px !important;
  border: none !important;
  border-radius: 8px;
}

.cartMgr-right-side-inner {
  background-color: #f6f8fb;
  border-radius: 8px;
  padding: 24px;
}

.content-striped {
  background-color: #ffffff;
  border: 1px solid #f6f8fb;
  border-radius: 8px;
  margin-bottom: 8px;
}

.cart-product-quantity {
  width: 80px !important;
  height: 37px !important;
}

.quantity-price-container {
  justify-content: flex-end;
  align-items: center;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.cart-wishlist-img {
  padding: 8px;
  background-color: #e6effb;
  color: $theme-blue;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.cart-wishlist-product-img {
  position: absolute;
  right: 0px;
  top: 0;
}

.vertical-align-checkout {
  min-height: 80px;
}

.cart-product-title {
  color: black;
}

.sku-text {
  font-weight: bold !important;
}

.promo-code-input {
  height: 45px !important;
  font-size: 14px !important;
}

@media (max-width: 992px) {
  .cart-outer-container {
    // background-color: #e6effb;
    background-color: #f6f8fb;
  }

  .product-outer-container {
    background-color: unset;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .cartMgr-right-side-inner {
    background-color: unset;
    border-radius: 0px;
    padding: 0 0 40px;
  }
}

@media (max-width: 576px) {
  .cart-outer-container {
    // background-color: #e6effb;
    background-color: #f6f8fb;
  }

  .content-striped {
    min-height: 177px;
  }

  .quantity-price-container {
    position: relative;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .cart-wishlist-quantity-img {
    position: absolute;
    left: 96px;
    top: 10%;
  }

  .cart-product-title {
    color: $theme-blue;
  }

  .promo-code-input {
    font-size: 12px !important;
  }
}

@media (min-width: 576px) {
  .vertical-align-checkout {
    justify-content: unset !important;
  }
}
