#filter-container {
  @include mobile() {
    position: inherit;
    width: 100%;
    left: 0;
    padding: 20px;
    z-index: 999999999;
    margin-top: -4px;
    background: #f6f8fb;
    top: -8px;
    height: 100%;
    overflow-x: auto;
  }
  .noUi-horizontal {
    height: 4px !important;
    border: 0;

    .noUi-base {
      .noUi-connects {
        .noUi-connect {
          background: #737b7d;
        }
      }
      .noUi-handle {
        height: 22px;
        width: 22px;
        border-radius: 30px;
        box-shadow: none;
        top: -9px;
        background: #1156a4;
        right: -7px;
      }
    }
  }
}

.order-dropdown {
  justify-content: flex-end;
  margin-left: 30px;

  @include mobile() {
    justify-content: flex-start;
    margin-left: 0;
  }
}

.filter-wrapper {
  background: #f6f8fb;
  padding: 24px !important;
  padding: 0;
  max-height: 700px;
  border-radius: 8px;
  overflow: auto;

  @include mobile() {
    background: transparent;
    position: fixed !important;
    top: -67px;
    left: 0;
    z-index: 1111111;
    height: 0;
  }
}
