.social-container {
  display: flex;
  position: absolute;
  right: 60px;
  top: 0;
  align-items: center;
}

@media (max-width: 767px) {
  .social-container {
    width: 100%;
    right: 0px;
    position: relative;
    justify-content: flex-end;
  }
}
