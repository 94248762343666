@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Light.ttf");
  font-weight: 300;
  direction: rtl;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Regular.ttf");
  direction: rtl;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-SemiBold.ttf");
  font-weight: 600;
  direction: rtl;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Bold.ttf");
  font-weight: 700;
  direction: rtl;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-ExtraBold.ttf");
  font-weight: 800;
  direction: rtl;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Black.ttf");
  font-weight: 900;
  direction: rtl;
}
