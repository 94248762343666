.order-outer-container {
  background-color: #f6f8fb;
  border-radius: 8px;
  padding: 24px;
}

.orders-list {
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 15px;
}

.order-inner-item {
  background: #ffffff;
  padding: 12px;
  border-radius: 8px;
}

.cart-order-quantity {
  width: 100px;
  height: 48px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #eaecee;
  border-radius: 8px;
  margin: 0 15px;
  padding: 12px 16px;
}

.text-last-step {
  display: none;
}

@media (max-width: 576px) {
  .cart-order-quantity {
    width: 70px;
  }
}
