.cmsViewPage_landing_texts_container {
  padding: 0 200px;

  p {
    font-weight: bold;
    a {
      color: $primary;
      font-size: 18px;
      text-decoration: underline;
    }
  }
}

@media (max-width: 991px) {
  .cmsViewPage_landing_texts_container {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .cmsViewPage_landing_texts_container {
    p {
      a {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 576px) {
  .cmsViewPage_landing_texts_container {
    p {
      a {
        font-size: 14px;
      }
    }
  }
}
