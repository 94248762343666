.navbar {
    height: 67px;
    .nav-item {
        .nav-link {
            color: $color-white !important;
        }
    }
}

.nav-container {
    @include mobile() {
        flex-direction: column-reverse;
    }
    > :first-child {
        @include mobile {
            padding: 0.5rem 0rem;
            // padding: 0.5rem 1rem !important;
        }
    }
    > :last-child {
        @include mobile {
            //     padding: 0.5rem 0rem;
            padding: 0.5rem 1rem !important;
        }
    }
}

.navbar-menu-bar {
    background-image: url("data:image/svg+xml,<svg width='24' height='19' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 1.5H22.5M1.5 17.5H22.5H1.5ZM1.5 9.5H22.5H1.5Z' stroke='#F6F8FB' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>") !important;
}

.shopping-cart-count {
    position: absolute;
    right: -11px;
    border-radius: 50%;
    background: $danger !important;
    color: white !important;
    text-align: center;
    top: 0;
}

.navbar-logo {
    display: inline-block;
    padding-top: 0.1125rem;
    padding-bottom: 0.1125rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    width: fit-content !important;
}

.dropdown-menu-full {
    background: #f6f8fb;
    width: 100% !important;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0.78rem 5rem !important;
    margin-top: 0 !important;
    min-height: 18.625rem;

    @include mobile() {
        background-color: #1156a4 !important;
        padding: 0 !important;
        border: none !important;
    }

    .smallBanner {
        .banner-container {
            position: relative;
            background-size: cover;
            border-radius: 8px;
            width: 100%;
            height: 205px;
            .banner-overlay {
                height: 100%;
                width: 100%;
                position: absolute;
                border-radius: 8px;
                background: linear-gradient(180deg, rgba(12, 87, 163, 0) 0%, rgba(12, 87, 163, 0.7) 71.35%);
            }
            .product-detail {
                position: absolute;
                bottom: 0;
                margin: 20px;
                h4,
                p {
                    color: #ffff;
                }
            }
        }
        img {
            border-radius: 8px;
        }
    }

    .dropdown-item {
        &:hover {
            background: none;
            text-decoration: underline;
        }
    }
}

.nav-bar-menu {
    @include mobile() {
        background: $primary;
        position: absolute !important;
        width: 100%;
        left: 0;
        top: 0;
        padding-top: 118px;
        padding-right: 10px;
        z-index: 4;
    }
}

.second-nav-menu.show {
    display: flex !important;
}

.user-icon {
    font-size: 15px;
    margin-left: 10px;
}

.user-name {
    font-size: 14px;
    margin-left: 5px;
}
