.btn-social {
  @include btn-social( $color-social, transparent );
  @include hover {
    color: $white;
  }
  width: 2.5em;
}

.btn-facebook {
  @include btn-social( $white, $color-facebook );
}

.btn-google {
  @include btn-social( $white, $color-google-plus );
}

.btn-twitter {
  @include btn-social( $white, $color-twitter );
}

.btn-pinterest {
  @include btn-social( $white, $color-pinterest );
}