.custom-breadcrumb-style {
  // @media (max-width: 992px) {
    // margin-top: 45px;
    margin-top: 20px;
  // }
  nav {
    ol {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.custom-breadcrumb-style-unauthorised {
  margin-top: 3rem;
  @media (max-width: 992px) {
    margin-top: 5.5rem;
  }
}