

//START: OVERRIDE BOOTSTRAP VARIABLES
$greenk:                 #08FACC;
// $greyk:                  #1A1110;
// $primary:                $greyk;
$nav-link-padding-y:     .3rem;

$primary:                #1156A4;
$secondary:              #0C5273;
$success:                #11A44D;
$warning:                #F98600;
$danger:                 #F52F2F;
$dark:                   #000000;


$font-family-base:       "Rubik";

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px
);

$headings-line-height:   1.3;
$line-height-base:       1.7;
$line-height-lg:         1.7;
$line-height-sm:         1.7;

// $custom-file-text: (
//   en: "Browse"
// ) !default;

$breadcrumb-font-size:   14px;
$breadcrumb-bg:          transparent;

$carousel-control-color: #000000;
$carousel-control-icon-width:       11px;

//END: OVERRIDE BOOTSTRAP VARIABLES

$enable-rounded:         false;
// $height-navbar-brand:    87px;
// $height-navbar-brand-lg: 133px;
$height-navbar-brand:    133;
$height-navbar-brand-lg: 133;
$color-facebook:         #4267B2;
$color-google-plus:      #DB4437;
$color-twitter:          #00ACEE;
$color-pinterest:        #C8232C;
$z-index-100:            100;
$zindex-dropdown:        1001;

// Theme Colors
$theme-blue:             #1156A4;
$theme-light-blue:       #1156A4;
$theme-dark-blue:        #0C5273;
$theme-green:            #11A44D;
$theme-warning:          #F98600;
$theme-orange:           #FF4E00;
$color-white:            #FFFFFF;
$color-black:            #000000;

// Paragraph font sizes
$p-desk-medium:          22px;
$p-desk-normal:          18px;
$p-desk-small:           14px;

$p-mob-medium:           18px;
$p-mob-normal:           14px;
$p-mob-small:            10px;

$a-normal:               14px;

$breakpoint-mobile:       576px;
$breakpoint-tablet:       768px;
$breakpoint-tablet-air:       820px;
$breakpoint-tablet-mini: 970px;