.page-item .page-link {
  border: none;
  margin: 3px;
  color: black;
}

.page-link {
  border-radius: 8px;
}

#pagination-prev:focus {
  box-shadow: none !important;
}

#pagination-next:focus {
  box-shadow: none !important;
}
