.elastic-item-container {
  height: 106px;
  display: flex;
  justify-content: space-between;
  padding: 11px 0;
  text-decoration: none !important;
  border: 1px solid $gray-400;
  border-left: none;
  width: inherit;
  @include media-breakpoint-down(md) {
    padding: 11px 3px !important;
  }
  &:hover,
  &.selected {
    background-color: $gray-200;
  }
}

.result-product-container {
  display: flex;
  flex-direction: column-reverse;
  @include media-breakpoint-up(lg) {
    flex-direction: unset;
  }
  &:hover,
  &.selected {
    text-decoration: none;
  }
}

.elastic-item-link {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column-reverse;
  @include media-breakpoint-up(lg) {
    width: 80%;
    flex-direction: unset;
  }
  &:hover,
  &.selected {
    text-decoration: none;
  }
}

.elastic-item-text-box {
  display: flex;
  flex-direction: column;
  height: 80px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 75%;
  max-width: 75%;
  @media (min-width: 420px) {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

.elastic-item-title-and-price {
  display: flex;
  justify-content: space-between;
}

.elastic-item-title {
  span {
    font-size: 1.1rem;
  }
}

ul[data-zg-role="typeahead-ul-elastic"] {
  padding: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 9;
  position: absolute;
  height: auto;
  width: 771px;
  left: -600px;
  @include media-breakpoint-down(md) {
    width: 100%;
    left: 0;
  }
  @media (min-width: 1150px) {
    left: -570px;
  }
  .loading {
    position: absolute;
    min-height: 100px;
  }
}

.elastic-item-price {
  background-color: $gray-400;
  border-radius: 4px;
  padding: 2px 4px;
  height: 28px;
}

.elastic-item-desc {
  font-size: 0.8rem;
  color: $gray-600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 83.333333%;
}

.elastic-img-container {
  overflow: hidden;
  img {
    height: auto;
    width: 61px;
    font-size: 0.8rem;
    word-break: break-all;
  }
}

ul[data-elastic="suggestions"] {
  border-left: 1px solid $gray-400;
  width: 100%;
  padding: 0;
  @include media-breakpoint-up(lg) {
    width: 45.2%;
    position: absolute;
    right: -69px;
    top: 0;
  }
  p {
    height: auto;
    font-weight: 600;
    padding: 11px 34px;
    margin-bottom: 0;
  }
}

ul li[data-elastic="suggestion"]:nth-child(n + 3) {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

ul div.result-product-container:nth-child(n + 4) {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

li[data-elastic="suggestion"] {
  font-weight: bold;
  list-style: none;
  padding: 11px 34px;
  position: relative;
  z-index: 10;
  width: 277px;
  cursor: pointer;
  em {
    font-weight: normal;
    font-style: normal;
  }
  @include media-breakpoint-down(md) {
    border-top: 1px solid $gray-400;
    padding: 11px 18px;
    width: 100%;
  }
  &:hover,
  &.selected {
    background-color: $gray-200;
  }
}

span[data-elastic="name"],
div[data-elastic="desc"] {
  em {
    font-weight: bold;
    font-style: normal;
  }
}

.search-main-container {
  border: 1px solid $gray-400;
  border-radius: 36px;
  display: flex;
  @include media-breakpoint-down(md) {
    border: 1px solid transparent;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 36px;
    &:hover,
    &.selected {
      background: $gray-200;
    }
  }
}

input[data-zg-role="search-elastic-input"] {
  padding-left: 40px;
  border-radius: 8px;
  height: 40px;
  // padding-right: 60px;
  @include mobile() {
    padding-left: 28px;
  }
  @include media-breakpoint-down(md) {
    // padding-right: 130px;
    border-radius: 4px;
  }
  &:focus {
    border-color: $gray-400;
    box-shadow: none;
  }
}

#searchElasticContainer {
  width: 16vw;
  max-width: 200px;
  @include media-breakpoint-down(md) {
    position: absolute;
    left: 0;
    background: white;
    top: 104%;
    height: 50px;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    padding: 0 13px;
  }
}

.search-icon {
  position: absolute;
  z-index: 4;
  top: 15%;
  left: 3%;
  @include mobile() {
    top: 25%;
  }
}

.search-btn {
  position: absolute;
  right: 0;
  z-index: 3;
  #typeahead-search-button {
    @include media-breakpoint-down(md) {
      color: $white;
      background: $black;
      width: auto !important;
      padding: 15px !important;
      font-weight: 600;
      border-radius: 0 4px 4px 0;
    }
    span {
      display: block;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
    i {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

.erase-btn {
  position: absolute !important;
  left: -60%;
  color: $gray-500;
  background: transparent;
  @include media-breakpoint-down(md) {
    font-size: 24px;
    left: -45%;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }
}

.opacity-search {
  width: 100vw;
  height: calc(100vh + 100px);
  opacity: 0.4;
  background: $gray-600;
  position: absolute;
  z-index: 2;
  display: block;
  top: -100px;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
}

#product-finder-elastic .input-group {
  z-index: 3;
}

#product-finder-elastic {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  width: 100%;

  @include mobile() {
    position: static;
    transform: none;
    width: 72% !important;
  }
}
