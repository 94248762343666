.orderMgr_list-order-number-container {
  display: flex;
  align-items: center;
}

.orderMgr_list-order-number {
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  font-size: 10px;
}

.d-flex,
.featured-products,
.order-vertical .col-3,
.order-vertical .col-6,
.order-vertical .col-lg-1,
.order-vertical .col-md-3 {
  display: inline-block !important;
}

.orderMgr_list-buttons-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orderMgr_list-button-custom-font {
  font-size: 12px !important;
  padding: 10px !important;
  width: 100%;
}

.orderMgr_list-custom-font {
  font-size: 14px;
}

.orderMgr_list-text-alignment {
  text-align: center;
}

@media (max-width: 1199px) {
  .orderMgr_list-button-custom-font {
    font-size: 9px !important;
    padding: 6px !important;
  }
}

@media (max-width: 767px) {
  .orderMgr_list-button-custom-font {
    font-size: unset !important;
  }

  .orderMgr_list-text-alignment {
    text-align: left;
  }
}

@media (max-width: 576px) {
  .orderMgr_list-button-custom-font {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}
