.wishlist_product_handlebars-item-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 15px;
  border-radius: 8px;
  background-color: #ffffff;
}

.wishlist_product_handlebars-wishlist-img {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  background-color: #e6effb;
  color: $theme-blue;
  border-radius: 50%;
  z-index: 1;
}

.wishlist_product_handlebars-img-container {
  img {
    width: 100px;
  }
}

.wishlist_product_handlebars-left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wishlist_product_handlebars-right-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wishlist_product_handlebars-item-remove {
  position: absolute;
  right: 0;
  top: 0;
}

.wishlist_product_handlebars-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px !important;
}

@media (max-width: 1200px) {
  .wishlist_product_handlebars-product-stock {
    font-size: 12px !important;
  }

  .wishlist_product_handlebars-sku {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .wishlist_product_handlebars-item-container {
    border: 1px solid #f6f8fb;
    padding: 15px 5px;
  }

  .wishlist_product_handlebars-price-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 12px !important;
  }

  .wishlist_product_handlebars-button {
    padding: 10px !important;
    font-size: 10px !important;
  }
}
