input {
  border-radius: 8px !important;
}

select {
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 8px;
}

.newsletter-email-input {
  width: 375px !important;
}

.rounded-circle-text {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 6px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}

.rounded-circle-right-side {
  margin-left: 42px;
}

.text-truncate-2-lines {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (max-width: 576px) {
  .newsletter-email-input {
    width: 255px !important;
  }

  .rounded-circle-right-text {
    max-width: 195px;
  }
}
