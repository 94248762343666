.contact-container-main {
  margin-bottom: 95px;
  @media (max-width: 992px) {
    margin-bottom: 32px;
  }
  h2 {
    font-weight: 700;
  }
  div {
    .contact-container {
      .medium {
        font-weight: 700;
      }
      form {
        background-color: #E6EFFB;
        padding: 32px;
        border-radius: 8px;

        fieldset {
          .form-group {
            .contact-input-field {
              width: 33%;
              border-radius: 8px;
              @media (max-width: 992px) {
                width: 100%;
              }
            }
            .contact-textarea {
              width: 33%;
              border-radius: 8px;
              @media (max-width: 992px) {
                width: 100%;
              }
            }
            select {
              border-radius: 8px;
              width: 33%;
              //-webkit-appearance: none;
              //-moz-appearance: none;
              //appearance: none;
              @media (max-width: 992px) {
                width: 100%;
              }
                .chevron-down-contact {
                  float: right;
                  color: #0f498b;
                }

            }
          }
          .custom-checkbox-contact {
            padding-left: 25px;
            label {
              font-size: 18px;
              cursor: pointer;
            }
            p {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}