#help-area {
  h3 {
    @media (max-width: 720px) {
      font-size: 28px !important;
    }
    font-size: 36px !important;
    font-weight: 700 !important;
    color: #0C0C0C;
    margin-bottom: 52px !important;
  }
  .help-mobile {
    display: none;
    @media (max-width: 720px) {
      display: block;
    }
    .custom-faq-card {
      border: none !important;
      background-color: #F6F8FB !important;
      margin: 16px 0;
      justify-content: center;
      .card-header {
        border-bottom: none;
        background-color: #F6F8FB;
        h2 {
          button {
            color: #434447;
            padding: 11px 14px !important;
            text-decoration: none;
            font-weight: 700;
            &:after {
              top: 8.59px;
              color: #1156A4;
            }
            &:focus {
              box-shadow: none;
            }
          }
          button.collapsed {
            font-weight: 400;
          }
        }
      }
      .collapse.show {
        background-color: #F6F8FB;
      }
    }
  }
  .help-desktop {
    @media (max-width: 720px) {
      display: none !important;
    }
    .col-4 {
      .custom-faq-nav-pills {
        background-color: #F6F8FB;
        border-radius: 8px;
        .custom-faq-nav-link {
          color: #000000;
          font-size: 22px;
          padding: 13.5px 32px;
        }
        .custom-faq-nav-link.active {
          font-weight: 700;
          background-color: #F6F8FB;
          border-radius: 8px;
        }
      }
    }
    .col-8 {
      padding: 24px;
      background-color: #E6EFFB;
      border-radius: 8px;
      .custom-faq-tab-content {
        padding: 16px;
        background-color: #ffffff;
        border-radius: 8px;
      }
    }
  }
}


