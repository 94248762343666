.product-preview article {
  height: unset !important;
  padding: 16px !important;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 5px #ced4da;
}

.product-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.product-card {
  display: flex;
  flex-direction: row;
  background: white;
  border: 1px solid #f6f8fb;
  border-radius: 8px;
}

.product-options {
  width: 100%;
  margin-left: 10px;
}

.container-1 {
  width: 100%;
  position: relative;
  min-height: 60px;

  @include mobile() {
    margin-bottom: 5px;
  }
  .product-id {
    @include mobile() {
      display: none;
    }
  }
}

.images-container {
  width: 100%;
}

.product-stock-badge {
  width: 180px;
}

.wish-list-img {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  background-color: #e6effb;
  color: $theme-blue;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.container-2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile() {
    position: static;
    align-items: flex-start;
  }
}

.sku-availability-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.price-old {
  font-size: 16px;
}

.price-actual {
  font-size: 20px;
}

.price-button-container {
  text-align: right;
  min-width: 270px;
}

.price-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.quantity-detail-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  .product-quantity {
    width: 80px !important;
    height: 37px !important;
    text-align: center;
    margin-left: 5px;

    @include mobile() {
      position: absolute;
      left: 10%;
    }
  }

  button {
    @include mobile() {
      font-size: 13px;
      padding: 12px !important;
    }
  }
}

.product-content {
  @include mobile() {
    justify-content: flex-start;
  }
  .images-container {
    img {
      width: 100px;
    }
  }

  .product-stock-badge {
    @include mobile() {
      display: none;
    }
  }
}
.product-preview .product-content .images-container img + img {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: unset !important;
}

.preview-product-wishlist-desktop-container,
.preview-product-wishlist-mobile-container {
  .preview-product-wishlist-desktop.added,
  .preview-product-wishlist-mob.added {
    font-weight: 900 !important;
  }
}

@media (max-width: 992px) {
  .product-options {
    margin-bottom: 0px;
  }

  .container-2 {
    flex-direction: column;
    align-items: flex-start;
  }

  .sku-availability-container {
    flex-direction: column;
  }

  .price-button-container {
    width: 100%;
    min-width: 100px;
  }

  .button-add-to-card {
    padding: 12px 20px !important;
  }

  .product-quantity {
    width: 50px !important;
  }

  .price-inner {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .price-old {
    font-size: 14px;
  }

  .price-actual {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .price-old {
    font-size: 12px;
  }

  .price-actual {
    font-size: 14px;
  }
}
