.btn {
  padding: 12px 32px !important;
}

.h1, h1 {
  font-size: 46px !important;
}

.h2, h2 {
  font-size: 36px !important;
}

.h3, h3 {
  font-size: 28px !important;
}

.h4, h4 {
  font-size: 20px !important;
}

.h5, h5 {
  font-size: 16px !important;
}

.h6, h6 {
  font-size: 16px !important;
}

span, p, div {
  font-size: $p-desk-normal;
}

span.medium, p.medium, div.medium {
  font-size: $p-desk-medium;
}

span.small, p.small, div.small {
  font-size: $p-desk-small;
}

a {
  font-size: $a-normal;
}

@media (max-width: 768px) {
  span, p, div {
    font-size: 16px;
  }

  span.medium, p.medium, div.medium {
    font-size: 20px;
  }

  span.small, p.small, div.small {
    font-size: 12px;
  }

}

@media (max-width: 576px) {
  .h1, h1 {
    font-size: 24px !important;
  }

  .h2, h2 {
    font-size: 20px !important;
  }

  .h3, h3 {
    font-size: 18px !important;
  }

  .h4, h4 {
    font-size: 16px !important;
  }

  .h5, h5 {
    font-size: 16px !important;
  }

  .h6, h6 {
    font-size: 16px !important;
  }

  span, p, div {
    font-size: $p-mob-normal ;
  }

  span.medium, p.medium, div.medium {
    font-size: $p-mob-medium;
  }

  span.small, p.small, div.small {
    font-size: $p-mob-small;
  }

}
