.header-custom-link {
  padding-left: 0px !important;
  margin-left: 12px !important;
}

.rounded-circle-cart-item-count {
  border-radius: 50%;
  width: 17px;
  height: 17px;
  font-size: 10px !important;
  padding: 4px 2px !important;
}