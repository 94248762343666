#challenge_iframe {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 9998;
}

.global-challenge-page{
  min-height: 400px;
}