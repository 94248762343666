.payment-method-link.active {
  background-color: white !important;
  border-radius: 8px;
  border: 2px solid #1156a4;
}

@media (max-width: 576px) {
  .orderMgr_payments-btn-proceed {
    font-size: 14px;
  }
}
