.random-products-main-container {
  margin: 136px 0 80px !important;
}

.random-product-item {
  margin: 3px 40px 10px;
}

.random-product-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f6f8fb;
  box-shadow: 0 0 5px #ced4da;
  border-radius: 8px;
  padding: 16px;
}

.random-product-discount-percentage {
  width: fit-content;
  position: absolute;
  right: 0;
}

.random-product-stock-badge {
  width: 130px;
}

.random-product-right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}

.random-product-price-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

// Carousel indecators
.random-product-carousel-indicators {
  margin-bottom: 0 !important;
  bottom: -20px !important;
}

.random-product-carousel-indicators {
  li {
    background-color: #1156A4 !important;
    border-top: none !important;
    border-bottom: none !important;
    border-radius: 20px !important;
    padding: 4px !important;
    width: 0px !important;
    height: 0px !important;
  }
}

@media (max-width: 992px) {
  .random-products-main-container {
    margin: 94px 0 52px !important;
  }

  .random-product-price-inner {
    width: 100%;
    padding-right: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .random-product-item {
    margin: 3px 2px 10px;
  }
}

@media (max-width: 400px) {
  .random-product-price-inner {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
