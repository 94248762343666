/*!
  * Bootstrap v4.6.0 (https://getbootstrap.com/)
  * Copyright 2011-2021 The Bootstrap Authors (https://github.com/twbs/bootstrap/graphs/contributors)
  * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
  */

@import "base/kooomo/km-fonts";
@import "base/kooomo/km-variables";
@import "base/kooomo/km-mixins";

// @import "theme/swiper";
@import "theme/navbar";
@import "theme/typography";
@import "theme/carousel";
@import "theme/input";
@import "theme/footer";
@import "theme/header";
@import "theme/filter";
@import "theme/userAdd";

@import "theme/_cmsViewPage_landing";
@import "theme/_cmsRandom_products";
@import "theme/categoryMgr";
@import "theme/active-filter-handlebars";
@import "theme/cmsComponent_masonry";
@import "theme/newsletter";
@import "theme/breadcrumb";
@import "theme/orderMgr_payments";
@import "theme/orderMgr_list";
@import "theme/address-handlebars";
@import "theme/accountSummary";
@import "theme/wishlist-product-handlebars";
@import "theme/wishlistMgr";
@import "theme/orderMgr_details";
@import "theme/orderMgr_rbsBT";
@import "theme/secureStripe2Form";
@import "theme/orderMgr_productsTable";
@import "theme/confirmMgr";
@import "theme/cartMgr";
@import "theme/cmsComponent_newsletter";
@import "theme/preview-product-handlebars";
@import "theme/productMangr";
@import "theme/pagination-item";

@import "theme/registration";

@import "theme/faq";
@import "theme/custombreadcrumb";
@import "theme/contact";

@import "theme/miniCart";

@import "base/bootstrap/functions";
@import "base/bootstrap/variables";
@import "base/kooomo/km-overwritten-variables";
@import "base/bootstrap/mixins";
@import "base/bootstrap/root";
@import "base/bootstrap/reboot";
@import "base/bootstrap/type";
@import "base/bootstrap/images";
@import "base/bootstrap/code";
@import "base/bootstrap/grid";
@import "base/bootstrap/tables";
@import "base/bootstrap/forms";
@import "base/bootstrap/buttons";
@import "base/bootstrap/transitions";
@import "base/bootstrap/dropdown";
@import "base/bootstrap/button-group";
@import "base/bootstrap/input-group";
@import "base/bootstrap/custom-forms";
@import "base/bootstrap/nav";
@import "base/bootstrap/navbar";
@import "base/bootstrap/card";
@import "base/bootstrap/breadcrumb";
@import "base/bootstrap/pagination";
@import "base/bootstrap/badge";
@import "base/bootstrap/jumbotron";
@import "base/bootstrap/alert";
@import "base/bootstrap/progress";
@import "base/bootstrap/media";
@import "base/bootstrap/list-group";
@import "base/bootstrap/close";
@import "base/bootstrap/toasts";
@import "base/bootstrap/modal";
@import "base/bootstrap/tooltip";
@import "base/bootstrap/popover";
@import "base/bootstrap/carousel";
@import "base/bootstrap/spinners";
@import "base/bootstrap/utilities";
@import "base/bootstrap/print";

@import "base/kooomo/km-custom";

@import "base/kooomo/km-social";
@import "base/kooomo/km-product";
@import "base/kooomo/km-global";
@import "base/kooomo/km-gritter";
@import "base/kooomo/km-comments-rating";
@import "base/fontawesome/brands";
@import "base/fontawesome/regular";
@import "base/fontawesome/solid";
@import "base/fontawesome/fontawesome";
@import "base/kooomo/km-utilities";
@import "base/kooomo/km-compare-products";
@import "base/kooomo/km-filter";
@import "base/kooomo/km-checkout";
@import "base/kooomo/nouislider";
@import "base/kooomo/km-payments";
@import "base/kooomo/km-cms-highlighter";
@import "base/kooomo/km-elasticsearch";

.card-equipment-categories {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100vw;
  margin: 0;  
  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;

    .image-logo {
      width: 300px;

      @include mobile() {
        width: 200px;
      }
    }

    .sub-title {
      font-size: 14px;
      position: absolute;
      bottom: 20%;
      color: $color-white;
      text-align: center;
      padding: 0 20px;

      @include mobile() {
        margin: 0;
        padding: 0;
        font-size: 10px;
        width: 337px;
        text-align: center;
      }
    }

    @include mobile() {
      height: 170px;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfd2d5;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b0b2b4;
}

.block-registration {
  height: 250px;
  a {
    .text-login {
      text-decoration: underline;
      font-size: 2rem;
    }
  }
}

.dropdown-order-by {
  border: 1px solid #eaecee;
  display: flex;
  border-radius: 8px !important;
  color: #000;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 190px;
  &:hover {
    border: 1px solid #eaecee !important;
    background-color: #ffffff !important;
    color: #000 !important;
  }
}

.carousel-item-custom {
  height: 590px;
  background-size: cover;
  background-position: center;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: -30%;
    right: 0;
    background: radial-gradient(50% 80% at 48% 54%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    opacity: .9;
    transform: rotate(0deg);
    height: 100%;
    width: 100%;

    @include mobile() {
      left: -30%;
      opacity: 0.9;
    }
  }

  @include mobile() {
    height: 400px;
  }
}

.news-letter-block {
  @include mobile() {
    margin-top: 75px;
  }
}

// because there is an overflow removed padding-left: 0;

//.main-container {
//  @include mobile() {
//    padding-left: 0;
//  }
//}

.polyphoto-link {
  @include mobile() {
    font-size: 16px !important;
    font-weight: bold;
  }
}

.border-radius-8 {
  border-radius: 8px;
}

.font-sz-14 {
  font-size: 14px;
}

.end-0 {
  left: initial;
  right: 0;
}

.swiper {
  height: 100%;
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-mobile-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  left: 50%;
  transform: rotate(90deg);
  transform-origin: left center;
}

.swiper-button-prev {
  top: 10px;
}

.swiper-button-next {
  top: auto;
  bottom: 10px;
}

.mobileSwiper {
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.background-gradient {
  position: absolute;
  bottom: 0;
  height: 100%;
  top: 0;
  z-index: -1;
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(17, 86, 164, 0.27) 0%, rgba(17, 86, 164, 0) 100%);
  left: -30%;
}

.swiper-button-next, .swiper-button-prev {
  opacity: 1;
  transition: 0.5s ease-in-out;
}
    
.swiper-button-disabled {
      visibility: hidden;
      opacity: 0;
      transition: 0.5s ease-in-out;
}

//data attribute styling for hiding some filter options

//.unwanted-data-category-id {
//  #filter_char_1951 {
//    display: none !important;
//  }
//}
//.unwanted-data-category-id[data-category-id="105403"] {
//  #filter_char_1951, #filter_char_2089, #filter_char_2091, #filter_char_2115, #filter_char_2160, #filter_char_2159 {
//    display: none !important;
//  }
//}
//.unwanted-data-category-id[data-category-id="105403"] {
//  #filter_char_2089, #filter_char_2091, #filter_char_2115, #filter_char_2160, #filter_char_2159 {
//    display: none !important;
//  }
//}
//.unwanted-data-category-id[data-category-id="105405"] {
//  #filter_char_2091 {
//    display: none !important;
//  }
//}
//.testing-data-category-id {
//  display: none;
//}