.login-tabs-container {
    .nav-tabs {
        .nav-item {
            position: relative;
            margin-right: 20px;
            .nav-link {
                position: relative;
                background: #f6f8fb;
                border-radius: 8px;

                .fa {
                    color: #1156a4;
                }
                h4 {
                    color: #434447;
                    font-weight: bold;
                    font-size: 20px;
                }
                .small {
                    color: #434447;
                    font-size: 14px;
                }
                &:hover::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border: 2px solid #1156a4;
                    border-radius: 8px;
                }
            }
            .active {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border: 2px solid #1156a4;
                    border-radius: 8px;
                }
            }

            &:last-child {
                margin: 0;
            }
        }
    }
}

.tab-container {
    margin: 25px 0;
    .login-tab {
        background: #e6effb;
        border-radius: 8px;
        .login-container {
            display: flex;
            justify-content: center;
            height: fit-content;
            padding: 42px;
            align-items: center;

            .login {
                padding: 43px;
                background: #fff;
                border-radius: 8px;

                @include mobile() {
                    padding: 20px 22px;
                }
                form {
                    padding: 0 130px;
                    margin-top: 25px;
                    @include mobile() {
                        padding: 0;
                    }
                    .btn-forgot-password {
                        text-align: right;
                        font-size: 16px;
                        font-weight: bold;
                        margin: 0;
                        padding: 0 !important;
                    }
                    .btn-login-container {
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        margin-top: 30px;
                        .btn-login {
                            width: fit-content;
                            padding: 12px 70px !important;
                        }
                    }
                    @include tablet() {
                        padding: 0;
                    }
                    @include tabletAir() {
                        padding: 0;
                    }
                    @include tabletMini() {
                        padding: 0;
                    }
                }
            }
        }
    }

    .register-tab {
        .register-section {
            background: #e6effb;
            border-radius: 9px;
            padding: 25px;
        }
    }
}

.form-select {
    border-radius: 8px !important;
}

.sign-up-form-container {
    .form-group {
        label,
        input {
            font-size: 15px;
        }
    }
}

.privacy-policy-checkbox {
    label {
        font-size: 18px;

        @include mobile {
            font-size: 14px;
        }

        @include tablet() {
            font-size: 14px;
        }
    }

    a {
        padding: 0 !important;
        margin: 0;
        text-align: start;
        font-size: 14px;
        text-decoration: underline;
    }
}

.required-field-label {
    font-size: 14px;
}

@media (max-width: 990px) {
    .tab-container {
        .login-tab {
            .login-container {
                .login {
                    form {
                        padding: 0;
                    }
                }
            }
        }
    }
}
