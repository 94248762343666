.w-40 {
  width: 40%;
}

.breadcrumb-cart-steps-custom-font {
  padding: 20px 0px;
}

@media (max-width: 576px) {
  .breadcrumb-cart-steps-custom-font {
    padding: 15px 0px;
    font-size: 10px !important;
  }
}
