.newsletter-container {
  padding: 60px 150px 180px;
  align-items: center;
  justify-content: center;
}

.newsletter-right-container {
  background-color: #e6effb;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .newsletter-right-container {
    height: 200px;
    padding: 0px 120px;
  }

  .newsletter-container {
    padding: 0px 0 60px;
  }
}
