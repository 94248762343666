.product-title {
    font-size: 36px !important;
    color: #0c0c0c;

    @include mobile() {
        font-size: 22px !important;
        font-weight: bold;
    }
}

.product-sku,
.product-availability {
    font-size: 16px;
    span {
        font-size: 16px !important;
        text-transform: uppercase;
    }
}

.section-gallery {
    display: flex;
    justify-content: space-evenly;

    @include mobile() {
        flex-direction: column-reverse;
    }
    @include tablet() {
        flex-direction: column-reverse;
    }
    @include tabletAir() {
        flex-direction: column-reverse;
    }

    .thumbnails {
        height: 485px;
        display: flex;
        flex-direction: column;

        .swiper-button-prev, .swiper-button-next {
            left: unset;
            transform: unset;
            transform-origin: unset;
        }
        .swiper-button-prev > .fa-chevron-left, .swiper-button-next > .fa-chevron-right {
            padding-bottom: 19px;
        }

        @include mobile() {
            overflow-x: auto;
            height: fit-content;
        }

        @include tablet() {
            overflow-x: auto;
            height: fit-content;
        }
        @include tabletAir() {
            overflow-x: auto;
            height: fit-content;
        }
    }

    .image-box {
        padding: 1px;
        border: 1px solid #e6effb;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 405px;
        @include mobile() {
            width: auto;
        }
        @include tablet() {
            width: auto;
        }
        @include tabletAir() {
            width: auto;
        }
    }

    .gallery-items {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .swiper-wrapper {
            margin-top: 10px !important;
            .gallery-item {
                height: auto !important;
                margin-bottom: 10px !important;
            }
        }
        .col {
            margin-bottom: 15px;
            .active {
                border: 1px solid #1156a4;
                padding: 2px;
                border-radius: 8px;
            }

            .thumbnail {
                img {
                    width: 100px;
                }
                @include mobile() {
                    width: 100%;
                }
            }
        }
        @include mobile() {
            overflow-x: hidden;
            flex-direction: row;
        }
        @include tablet() {
            width: 200%;
            overflow-x: hidden;
            flex-direction: row;
        }
        @include tabletAir() {
            width: 200%;
            overflow-x: auto;
            flex-direction: row;
        }
    }
}

.product-shipping-details {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // border-bottom: 1px solid #ced4da;

    p {
        font-size: 14px;
        color: #737b7d;

        span {
            font-size: 14px;
            font-weight: bold;
            color: #0c0c0c;
        }
    }

    @include mobile() {
        justify-content: space-evenly;
        padding: 0;
        height: 66px;
        align-items: unset;
    }
}

.btn-container-cart {
    margin-top: auto;

    .price {
        text-align: right;
        del {
            color: #ff7e27;
            font-size: 18px;
            margin-right: 18px;

            @include mobile() {
                font-size: 16px;
            }
        }
        span {
            font-size: 30px;
            color: #1156a4;

            @include mobile() {
                font-size: 26px;
            }
        }
    }

    .add-cart-container {
        .quantity {
            display: flex;
            align-items: center;
            flex-direction: row;
            // justify-content: space-between;

            @include mobile() {
                margin-left: 0;
                padding-left: 0;
            }
            h6 {
                @include mobile() {
                    display: none;
                }
            }

            input {
                width: 40% !important;
                text-align: center !important;
                margin: 0 !important;
                
                @include mobile() {
                    width: 100% !important;
                }
            }
        }

        .btn-cart {
            font-size: 15px;
            background-color: #1156a4 !important;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #0f498b !important;
            }

            @include mobile() {
                font-size: 13px;
                padding: 12px 20px !important;
            }
        }
    }

    @include mobile() {
        margin-top: 25px;
    }
}

.product-details{
    header{
        border-bottom: 0 !important
    }
}

.product-details-tab {
    margin-top: 70px;
    display: block;

    ul {
        width: 100%;
        justify-content: space-between !important;

        .nav-item {
            flex-grow: 1;
            margin-right: 20px;
            width: 200px;

            .nav-link {
                width: 100%;
                height: 90px;
                border-radius: 8px;
                background: #f6f8fb;
                color: #000;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;

                span {
                    font-size: 14px;
                    text-align: center;
                    color: #434447;
                    margin-top: 6px;
                }

            }

            &:last-child {
                margin-right: 0;
            }
        }
        .active {
            border: 2px solid #1156a4 !important;
        }
    }

    @include mobile() {
        display: none;
    }
}

.product-detail-accordion {
    display: none;
    margin-top: 10px;

    .card {
        margin-bottom: 8px;
        display: flex;
        border: none;
        background: #f6f8fb;
        justify-content: center;

        .card-header {
            padding: 0;
            background: #f6f8fb !important;
            border-bottom: none !important;
            h2 {
                button {
                    padding-left: 15px !important;
                    &:focus,
                    &:active {
                        outline: none !important;
                        box-shadow: none;
                    }
                    img {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    @include mobile() {
        display: block;
    }
}

.suggestedProducts {
    .may-also-like {
        padding: 14px;
        position: relative;
        min-height: 195px;
        margin-bottom: 20px;
        border: 1px solid #f6f8fb;
        border-radius: 8px;
        .thumbnail {
            min-height: 160px;
        }
        .product-content {
            margin-left: 20px;
            height: 165px;
            flex-grow: 1;
            .product-brand {
                text-align: left;
                font-size: 14px;
                margin: 0;
                padding: 0;
            }
            .related-product-title {
                font-size: 20px;
                font-weight: bold;
                text-align: left;
            }
            .related-product-footer {
                width: 100%;

                .product-price {
                    color: #1156a4;
                    font-size: 14px;
                    text-align: right;
                }
            }
            .related-product-footer {
                .related-product-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .related-product-code,
                    .related-product-availability {
                        text-align: left;
                        font-size: 14px;
                        span {
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                    .related-product-cart {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .cart-wishlist-quantity-img {
                            position: absolute;
                            right: 15px;
                            top: 8px;
                        }
                    }
                }
            }
        }
    }
}

.tab-conter-text {
    font-size: 16px;
    span {
        font-size: 16px;
    }
}

.product-thumbnail-image {
    height: 95px;
    width: 95px;
    background-position: center;
    background-size: cover;
}

.swiper-wrapper {
    margin-top: 30px;
}

.unauthorized-container {
    height: calc(100vh - 400px);
    width: 700px !important;

    h2 {
        text-align: center;
        color: #1156a4;
        font-weight: bold;
    }

    p {
        text-align: center;
        a {
            font-size: 18px;
            text-decoration: underline !important;
        }
    }

    .contact {
        font-size: 16px;
        font-weight: bold;
    }

    @include mobile() {
        width: 100% !important;
    }
}

.gallery-view-vertical {
    display: none !important;
}

@media (min-width: 768px) and (max-width: 991px)  {
    .btn-cart{
        padding: 12px 10px !important;
    }
}

@media (min-width: 1200px)  {
    .gallery-view-vertical {
        display: flex !important;
    }
}
