.custom-bg-black::after{
    background: radial-gradient(50% 80% at 48% 54%,#1f1f1f 0,rgba(255,255,255,0) 100%);
}

.carousel-caption-custom {
    position: absolute;
    left: 5% !important;
    top: 20% !important;
    text-align: left !important;

    h1 {
        @include mobile() {
            font-size: 28px !important;
            font-weight: bold;
        }
    }

    .h4 {
        font-size: 22px;
        @include mobile() {
            font-size: 22px !important;
            text-align: left;
        }
    }

    .btn-primary {
        @include mobile() {
           font-size: 11px;;
        }
    }

    @include mobile() {
        width: 250px;
        left: 8% !important;
    }
}

.carousel-item-custom {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include mobile() {
        height: 450px;
    }
}

.carousel-indicator-custom {
    height: fit-content;
    position: absolute;
    left: 4% !important;
    top: 100px !important;
    justify-content: flex-start !important;
    margin-left: 0 !important;

    li {
        width:87px !important;
        background-color: #1156A4 !important;
        border-top: 3px solid #1156A4 !important;
        border-bottom: 3px solid #1156A4 !important;
        opacity: 0.2;
        border-radius: 300px;
        background-clip: inherit !important;
    }

    @include mobile() {
        top: 70px !important;
        left: 4% !important;
    }
}