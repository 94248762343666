.header-custom-cart-drop-down {
  .loading {
    position: absolute !important;
  }
}

.cart-dropdown-item {
  position: static !important;

  a {
    position: relative;
  }

  .dropdown-cart-custom {
    top: -2px;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    // animation: rotateY 300ms ease-in-out forwards;
    // transform-origin: top center;

    // transition: transform 0.6s, opacity 0.6s;
    // transform:translateX(195px) translateY(0%);
    // translateX(100px, 375px) translateY(0px)
  }

  .header_mini_cart-container {
    width: 500px;
    height: 100%;
    position: absolute;
    padding: 25px;
    top: 0;
    right: 0;
    background-color: #f6f8fb;
    border: none;
    border-radius: 0;

    animation-duration: 0.5s;
    animation-name: rightToLeft;

    .mini-cart-custom-title {
      margin-left: 24px;
    }
  }
}

.cart-item-container {
  height: calc(100% - 250px);
  margin-top: 30px;
  display: block !important;
  overflow: auto;

  .cart-dropdown {
    justify-content: center;
    background: #ffff;
    height: 185px;
    border: none;
    border-radius: 8px;
    padding: 10px 0;

    .cart-product {
      .mini-cart-product-link {
        height: 60px;

        a {
          color: black;
          position: relative;
        }

        a:hover {
          color: black !important;
        }
      }
    }
  }
}

.mini-cart-left-container {
  display: flex;
  align-items: center;
}

.mini-cart-right-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.btn-remove-item {
  font-size: 16px !important;
  text-decoration: underline;

  :focus {
    box-shadow: none !important;
  }

  img {
    width: 18px;
    margin-bottom: 4px;
  }
}

.text-muted-light {
  color: #d0d0d0;
}

.price-detail-card {
  margin-top: 24px;

  .subtotal-info {
    display: flex;
    justify-content: space-between;
  }

  .shipping-info {
    display: flex;
    justify-content: space-between;
  }

  .tax-info {
    display: flex;
    justify-content: space-between;
  }

  .price-info {
    display: flex;
    justify-content: space-between;

    .total-label,
    .amount {
      font-weight: bold;
      font-size: 24px;
      color: #1156a4;
    }
  }
}

.payment-detail-button {
  font-size: 16px !important;
}

.cart-title {
  font-size: 28px;
}

.mini-cart-quantity {
  background: #eaecee;
  width: fit-content;
  border: 1px solid;
  padding: 6px 25px;
  display: flex;
  border: 1px solid #eaecee;
  border-radius: 8px;
}

.dropdown-item-empty-container {
  margin: 40px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.dropdown-item-empty-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: white;
}

.product-amount-cart {
  text-align: right;

  h3 {
    color: #1156a4;
    font-weight: 600;
  }
}

@media (max-width: 767px) {
  .cart-item-container {
    margin-top: 10px;
    height: calc(100vh - 350px);
  }

  .mini-cart-right-container {
    position: unset !important;
  }

  .mini-cart-left-container {
    display: flex;
    align-items: unset;
  }

  .product-amount-cart {
    width: 100%;
  }

  .mini-cart-quantity-container {
    position: absolute;
    left: 16px;
    bottom: 10px;
  }

  .price-detail-card {
    margin-top: 10px;
  }

  .cart-dropdown-item {
    .header_mini_cart-container {
      width: 100%;
      height: calc(100vh - 125px);
      top: unset;
      bottom: 0;
      border-radius: 8px 8px 0 0;

      animation-name: bottomToTop;
    }
  }

  .mini-cart-custom-dropdown-mb {
    position: relative;
    height: 130px !important;
  }

  .payment-detail-button {
    width: 100%;
    padding: 8px !important;
  }
}

@keyframes rightToLeft {
  0% {
    right: -500px;
  }

  100% {
    right: 0px;
  }
}

@keyframes leftToRight {
  0% {
    right: 0px;
  }

  100% {
    right: -500px;
  }
}

@keyframes bottomToTop {
  0% {
    bottom: -89%;
  }

  100% {
    bottom: 0;
  }
}
