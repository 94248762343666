.position_compare_products_right {
  left: auto !important;
  right: 5px !important;
}

#compare-products-container {
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.15);
  bottom: 34px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  position: fixed;
  transition: all 0.3s ease;
  width: 300px;
  z-index: $zindex-dropdown;
  left: 5px;

  &.collapsed {
    bottom: -243px;
  }
}

.toggle-compare {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  color: $black;
  bottom: 0;
  cursor: pointer;
  height: 35px;
  line-height: 25px;
  left: 5px;
  padding: 5px;
  position: fixed;
  text-align: center;
  width: 300px;
  z-index: 1001;
}


.compare-product-item {
  text-align: center;

  a.btn-primary {
    white-space: normal;
  }

  .dl-compare-products {
    dd {
      margin-bottom: 7px;
    }
  }
}

.container-modal-compare-products {
  .row {
    margin: 0;
    padding: 8px;
  }
}