#gritter-notice-wrapper,
#dropdown-cart-item-empty-inner {
  position: fixed;

  /*bottom: 0;
  right: 0;*/

  top: 9%;
  right: 0;
  z-index: 9999;
  width: 350px;
  max-width: 100%;

  /*padding: 0 20px;*/
  padding: 10px 10px;
}

.gritter-item-wrapper {
  position: relative;
  padding: 30px 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07), 0px 7px 14px rgba(65, 69, 88, 0.1);
}

.gritter-title {
  display: none;
}

.gritter-item p {
  margin: 0;
}

.gritter-close {
  //&:extend( .close all );
  position: absolute;
  top: 3px;
  right: 6px;
  // display: none; //earlier display: none was set, but not needed in our case

  &:before {
    content: "\00D7";
  }
}

@media (max-width: 2560px) {
  #gritter-notice-wrapper,
  #dropdown-cart-item-empty-inner {
    right: 10%;
  }
}

@media (max-width: 1440px) {
  #gritter-notice-wrapper,
  #dropdown-cart-item-empty-inner {
    right: 3%;
  }
}

@media (max-width: 1200px) {
  #gritter-notice-wrapper {
    right: 9%;
  }
}

@media (max-width: 1024px) {
  #gritter-notice-wrapper,
  #dropdown-cart-item-empty-inner {
    right: 5%;
  }
}

@media (max-width: 767px) {
  #gritter-notice-wrapper,
  #dropdown-cart-item-empty-inner {
    top: 0;
    right: 0;
  }
}
