.masonry-outer {
  margin: 80px 0;
}

.masonrty-main-title {
  margin-bottom: 47px;
}

.masonry-item-link:hover {
  text-decoration: none;
}

.masonry-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 16px;
  padding: 75px 60px 0;
  border-radius: 8px;
}

.masonry-item-text {
  color: white;
}

.masonry-item-1,
.masonry-item-3 {
  height: 286px;
}

.masonry-item-4,
.masonry-item-6 {
  height: 378px;
}

.masonry-item-2 {
  height: 434px;
}

.masonry-item-5 {
  height: 230px;
}

// Mobile view slides
.masonry-swipper-container {
  .swiper-slide {
    background-color: transparent !important;
  }
}

.masonry-slide-item {
  width: 250px;
  height: 286px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.masonry-slide-text {
  color: white;
  padding: 75px 60px;
  text-align: left;
}

// Media Queries
@media (max-width: 992px) {
  .masonry-outer {
    margin: 50px 0;
  }

  .masonry-item {
    padding: 35px 10px 0;
  }

  .masonrty-main-title {
    margin-bottom: 11px;
  }

  .masonry-item-1,
  .masonry-item-3 {
    height: 206px;
  }

  .masonry-item-4,
  .masonry-item-6 {
    height: 238px;
  }

  .masonry-item-2 {
    height: 284px;
  }

  .masonry-item-5 {
    height: 160px;
  }
}

@media (max-width: 767px) {
  .masonry-outer {
    margin: 50px -30px 50px 0;
  }
}
